export default [
  {
    title: 'Inicio',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard',
  },
  {
    header: 'Navegación',
  },
  {
    title: 'Actividades',
    route: 'activities',
    icon: 'ClockIcon',
  },
  {
    title: 'Cuentas',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Empresas',
        route: 'business',
      },
      {
        title: 'Contactos',
        route: 'contacts',
      },
    ],
  },
  {
    title: 'Ventas',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Cotización',
        route: 'quotations',
      },
      {
        title: 'Venta',
        route: 'sales',
      },
    ],
  },
  {
    title: 'Guías',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Ver guías',
        route: 'guides',
      },
      {
        title: 'Conductores',
        route: 'drivers',
      },
      {
        title: 'Vehiculo',
        route: 'vehicles',
      },
    ],
  },
  {
    title: 'Productos',
    icon: 'GiftIcon',
    children: [
      {
        title: 'Ver productos',
        route: 'products',
      },
      // {
      //   title: 'Crear productos',
      //   route: 'product-create',
      // },
      {
        title: 'Categorias',
        route: 'product-categories',
      },
      {
        title: 'Marca',
        route: 'product-brands',
      },
      {
        title: 'Historial stock',
        route: 'product-history-stock',
      },
    ],
  },
  {
    title: 'Servicios',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Ver servicios',
        route: 'services',
      },
      // {
      //   title: 'Crear servicio',
      //   route: 'service-create',
      // },
      {
        title: 'Categorias',
        route: 'service-categories',
      },
    ],
  },
  {
    title: 'Reportes',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Reporte general',
        route: 'report-general',
      },
      {
        title: 'Reporte por vendedor',
        route: 'report-seller',
      },
      {
        title: 'Comisiones',
        route: 'commissions',
      },
    ],
  },
  {
    title: 'Web',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Slider',
        route: 'sliders',
      },
      {
        title: 'Alerta superior',
        route: 'alert-top',
      },
      {
        title: 'Modal principal',
        route: 'modal-main',
      },
      {
        title: 'Blog',
        route: 'blogs',
      },
      {
        title: 'Testimonios',
        route: 'testimonials',
      },
    ],
  },
  {
    title: 'Leads',
    icon: 'UserPlusIcon',
    children: [
      {
        title: 'Suscriptores',
        route: 'subscribers',
      },
    ],
  },
  {
    title: 'Configuracion',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'General',
        route: 'setting-general',
      },
      {
        title: 'Datos empresa',
        route: 'setting-business',
      },
      {
        title: 'Roles',
        route: 'roles',
      },
      {
        title: 'Usuarios',
        route: 'users',
      },
    ],
  },
]
